













































































































import { Component, Vue } from "vue-property-decorator";
import { getCount, getReservationCount } from "@/serve/home/index";
import { getSatisfactionStatistics } from "@/serve/evaluatingBefore/evaluatingStandard";
@Component({
  components: {},
  name: "home",
})
export default class Home extends Vue {
  public btnSomeOne = true;
  public motherFuck = 1;
  public orderNum = "";
  public orderPay = "";
  public leftWeekData = "";
  public leftMonthData = "";
  public nowDayData = {
    homeAddDate: {
      checkReport: "",
      counselNum: "",
      realNameVip: "",
    },
    homeToDay: {
      addVip: "",
      counselNum: "",
      orderNum: "",
      orderPay: "",
    },
  };
  public mook: string[] = [];
  public mookData: string[] = [];
  //统计数据
  public satisfactionData = {
    //总数
    totalCount: 0,
    //满意
    satisfiedCount: 0,
    //不满意
    unSatisfiedCount: 0,
  };
  // echarts传入数据
  public async created() {
    await this.getList();
    // await this.changeDay(this.leftWeekData);
    await this.getsatisfactionList();
    await this.getCharts(this.satisfactionData);
  }
  public mounted() {
    // this.getCharts();
  }

  //获取满意度问卷统计
  public getsatisfactionList() {
    getSatisfactionStatistics().then((res: any) => {
      console.log("满意度数据统计", res);
      this.satisfactionData.totalCount = res.data.totalCount;
      this.satisfactionData.satisfiedCount = res.data.satisfiedCount;
      this.satisfactionData.unSatisfiedCount = res.data.unSatisfiedCount;
      console.log("satisfactionData", this.satisfactionData);
      this.getCharts(this.satisfactionData);
    });
  }

  // 获取当前页面数据
  public async getList() {
    const res = await getCount();
    this.nowDayData = res.data;
    console.log("nowDayData", this.nowDayData);
    const params = {
      day: 7,
    };
    this.leftWeekData = await getReservationCount(params);
    console.log("具体数据", this.leftWeekData);
  }
  // echart 的方法
  public getCharts(data: any): void {
    console.log("初始化数据", data);
    const leftpic = this.$echarts.init(this.$refs.leftpic as HTMLCanvasElement);
    const righttpic = this.$echarts.init(
      this.$refs.righttpic as HTMLCanvasElement
    );
    leftpic.setOption({
      title: {
        text: "满意问卷占比率",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "问卷占比",
          type: "pie",
          radius: "50%",
          data: [
            { value: data.totalCount, name: "问卷总数",itemStyle: {color:'#fc8251'} },
            { value: data.satisfiedCount, name: "满意问卷",itemStyle: {color:'#5470c6'} },
          ],
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    });
    righttpic.setOption({
      title: {
        text: "差评问卷占比率",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "问卷占比",
          type: "pie",
          radius: "50%",
          data: [
            { value: data.totalCount, name: "问卷总数",itemStyle: {color:'#91cd77'}},
            { value: data.unSatisfiedCount, name: "差评问卷",itemStyle: {color:'#ef6567'}},
          ],
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    });
    setTimeout(function () {
      window.onresize = function () {
        leftpic.resize();
      };
    }, 200);
  }
}
